<script>
var moment = require("moment");
moment.locale("fr");
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import Swal from "sweetalert2";
import loaderProcess from "../../../components/widgets/loaderProcess.vue";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import categorieCard from "../../../components/widgets/categorieCard.vue";
import addButton from "../../../components/widgets/addButton.vue";
/**
 * Projects-list component
 */
export default {
  page: {
    title: "Projects List",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcess,
    categorieCard,
    addButton,
  },

  data() {
    return {
      titre: "",
      progress: 0,
      route1: "/projects/overviewCategorie/",
      route2: "/projects/updateCategories/",
      route3: "",
      process: false,
      modalOk: false,
      spinner: false,
      categ: {
        titre: "",
        description: "",
      },
      categories: [],
      moment: moment,
      title: "Liste des catégories",
      items: [
        {
          text: "Contenu",
          // href: "/projects/categories",
        },
        {
          text: "Catégories",
          active: true,
        },
      ],
    };
  },
  methods: {
    showModal() {
      this.modalOk = true;
    },

    imageCategorie(event) {
      for (let i = 0; i < event.target.files.length; i++) {
        const originalFile = event.target.files[i];
        const newName = `${i}${originalFile.name}${new Date().getTime()}`;

        // Créer un nouveau fichier avec le nouveau nom
        const renamedFile = new File([originalFile], newName, {
          type: originalFile.type,
          lastModified: originalFile.lastModified,
        });

        this.imageCat = renamedFile;
      }

      console.log(this.imageCat);
    },

    createCategorie() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
      };

      this.process = true;
      this.modalOk = false;
      this.titre = "Création de catégories";
      Api.postFormData(
        "/streamvod/rest/v2/category/create",
        {
          file: this.imageCat,
          description: this.categ.description,
          name: this.categ.titre,
        },
        onUploadProgress
      )
        .then(() => {
          this.process = false;
          Swal.fire("Succes!", "Catégorie ajoutée", "success");
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    this.spinner = true;
    Api.get("/streamvod/api/v2/category/all")
      .then((res) => {
        this.spinner = false;
        this.categories = res.data.content;
        console.log(this.categories);
      })
      .catch((error) => {
        Erreur.gestionErreur(error.message);
      });
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <loader-process :visible="process" :progress="progress" :title="titre" />
    <div style="display: flex; justify-content: end">
      <addButton :text="'Ajouter'" @click="showModal"></addButton>
    </div>
    <br />
    <div class="row">
      <categorieCard
        v-for="categ in categories"
        :key="categ.id"
        :categorie="categ"
        :route1="route1"
        :route2="route2"
        :route3="route3"
      ></categorieCard>

      <div style="text-align: center; font-size: 3em" v-if="spinner == true">
        <b-spinner variant="success" role="status"></b-spinner>
      </div>
      <div
        style="text-align: center; font-size: 3em"
        v-if="spinner == false && categories.length == 0"
      >
        <span style="font-size: 1.5rem">Aucune catégorie créée !</span>
      </div>
    </div>

    <b-modal
      v-model="modalOk"
      id="modal-xl"
      size="xl"
      title="Ajouter une catégorie"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <form>
          <div class="form-group row mb-4">
            <label for="projectname" class="col-form-label col-lg-2"
              >Titre</label
            >
            <div class="col-lg-10">
              <input
                id="projectname"
                name="projectname"
                type="text"
                class="form-control"
                v-model="categ.titre"
                placeholder="Entrez le titre de la catégorie"
              />
            </div>
          </div>

          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label col-lg-2"
              >Image</label
            >
            <div class="col-lg-10">
              <input
                id="projectbudget"
                name="projectbudget"
                type="file"
                @change="imageCategorie($event)"
                class="form-control"
                accept=".png, .jpeg, .jpg"
              />
            </div>
          </div>

          <div class="form-group row">
            <label for="projectdesc" class="col-form-label col-lg-2"
              >Description</label
            >
            <div class="col-lg-10">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="categ.description"
                placeholder="Entrez la description"
              ></textarea>
            </div>
          </div>
        </form>
        <br />
        <div class="row">
          <div
            style="display: flex; align-items: center; justify-content: center"
          >
            <addButton :text="'Ajouter'" @click="createCategorie"></addButton>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- end row -->
  </Layout>
</template>

<style></style>
